import React from "react";

const Button = React.forwardRef((props, ref) => {
  const {
    disabled,
    size,
    icon,
    iconLeft,
    iconRight,
    layout,
    block,
    children,
    tag = "button",
    ...rest
  } = props;

  const baseClasses = [
    "align-bottom",
    "inline-flex",
    "items-center",
    "justify-center",
    "cursor-pointer",
    "leading-5",
    "transition-colors",
    "duration-150",
    "font-medium",
    "focus:outline-none",
    layout === "outline" ? "text-gray-600 border-gray-300 border" : "",
    layout === "link" ? "text-gray-600 focus:outline-none border border-transparent" : "",
    layout === "primary" ? "text-white bg-purple-600 border border-transparent" : "",
    block ? "w-full" : "",
    size === "larger" ? "px-10 py-4 rounded-lg" : "",
    size === "large" ? "px-5 py-3 rounded-lg" : "",
    size === "regular" ? "px-4 py-2 rounded-lg text-sm" : "",
    size === "small" ? "px-3 py-1 rounded-md text-sm" : "",
    size === "pagination" ? "px-3 py-1 rounded-md text-xs" : "",
    disabled ? "opacity-50 cursor-not-allowed" : "",
  ]
    .filter(Boolean)
    .join(" ");

  const iconClasses = [
    size === "larger" || size === "large" ? "h-5 w-5" : "h-3 w-3",
    iconLeft ? "mr-2 -ml-1" : "",
    iconRight ? "ml-2 -mr-1" : "",
  ].join(" ");

  const ButtonTag = tag;

  return (
    <ButtonTag ref={ref} {...rest} className={baseClasses} disabled={disabled}>
      {iconLeft && <span className={iconClasses}>{iconLeft}</span>}
      {icon && !iconLeft && !iconRight && <span className={iconClasses}>{icon}</span>}
      {children}
      {iconRight && <span className={iconClasses}>{iconRight}</span>}
    </ButtonTag>
  );
});

export default Button;
