import React from 'react';
import CustomCircularProgress from "./Common/CircularProgress";

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex items-center justify-center p-6 text-lg font-medium text-gray-600">
      <CustomCircularProgress />
    </div>
  );
}

export default ThemedSuspense
