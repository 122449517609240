import { useState } from "react";

function useSnackAlert() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(null);
  const [message, setMessage] = useState(null);
  const [duration, setDuration] = useState(6000);

  const getProps = { severity, message, open, setOpen, duration };

  const showMessage = (severity, message, duration = 6000) => {
    setSeverity(severity);
    setMessage(message);
    setOpen(true);
    setDuration(duration);
  };
  return { showMessage, getProps };
}

export default useSnackAlert;
