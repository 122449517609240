import React from "react";
import { useNavigate } from "react-router-dom";

function NavigateButton() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate("/dashboard");
        window.location.reload();
      }}
      className="w-56 h-12 font-medium rounded text-sm bg-btnPrimary text-white"
    >
      Go to Dashboard
    </button>
  );
}

class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  navigateToDashboard = () => this.props.history.push("/app");

  render() {
    if (this.state.error) {
      console.error("Internal Error Handler:", this.state.error);
      return (
        <div className="h-[100vh] w-full bg-bgScreen">
          <div className="flex flex-col items-center p-8 h-full">
            <h2 className="my-5 text-red-500 font-bold text-2xl">
              Web Application Error
            </h2>
            <div className="w-auto h-auto border-2 rounded-md border-red-500 p-8">
              <label>
                Error Message :{" "}
                <span className="text-red-500 text-xl">{this.state.error.message}</span>
              </label>
              <br />
              <div className="flex">
                <label className="whitespace-nowrap">Error Stack:</label>
                <p className="border rounded-sm border-gray-400 p-4 m-2">
                  {this.state.error.stack}
                </p>
              </div>
              <div className="flex justify-center items-center w-full mt-4">
                <NavigateButton />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
