import React from "react";

const Input = React.forwardRef((props, ref) => {
  const { valid, disabled, type, ...rest } = props;

  const baseClasses = [
    "block",
    "w-full",
    "text-sm",
    "focus:outline-none",
    "leading-5",
    "rounded-md",
    valid
      ? "border-green-600 focus:border-green-400 focus:ring focus:ring-green-200"
      : "",
    disabled ? "cursor-not-allowed opacity-50 bg-gray-300" : "",
  ]
    .filter(Boolean)
    .join(" ");

  const inputType = type || "text";

  return (
    <input
      ref={ref}
      {...rest}
      type={inputType}
      className={baseClasses}
      disabled={disabled}
    />
  );
});

export default Input;
