// convert single digit to double digit number
export const convert = (n) => {
  n = String(n);
  if (n.length === 1) n = "0" + n;
  return n;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const removeLastDot = (e) => {
  let str = e;
  if (str[str.length - 1] === ".") {
    str = str.slice(0, -1); // to remove the last . string
  }
  return str;
};

export const dateNow = (format) => {
  let today = new Date().toDateString().split(" ");
  let date = `${today[0]} ${today[1]} ${today[2]} ${today[3]}`; // Thu Mar 24 2022
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (format === "MM/DD/YYYY") {
    return [month, day, year].join("/"); // MM/DD/YYYY
  } else {
    return [day, month, year].join("/"); // DD/MM/YYYY
  }
};

// i/p M/D/YYYY => o/p MM/DD/YYYY
export const convertDoubleDigit = (indata) => {
  let data = indata?.split("/");
  let temp = [];
  data?.map((e, i) => {
    let a = convert(e);
    temp.push(a);
    return null;
  });
  return temp?.join("/");
};

// for checking numbers only
// export const validateNumeric = (e) => {
//   if (e.match(/^\d+$/) === null) {
//     // alert("Please enter a numeric value ");
//     return false;
//   }
//   return true;
// };

// program to check if a number is a float or integer value
export function validateNumeric(e) {
  let x =
    e !== undefined && e !== "" && e !== null
      ? e?.toString()?.replace(",", "")?.replace(",", "")?.replace(",", "").trim()
      : e;

  if (x?.includes("-")) return false;
  let regexPattern = /^-?[0-9]+$/;
  let result = regexPattern.test(x);
  if (result) {
    // console.log(`${x} is an integer.`);
    return true;
  } else {
    if (!isNaN(x)) {
      if (Number.isInteger(x)) {
        // console.log(`${x} is integer.`);
        return true;
      } else if (x === null || x === "") {
        // console.log(`${x} is not a  number`);
        return false;
      } else {
        // console.log(`${x} is a float value.`);
        return true;
      }
    } else {
      // console.log(`${x} is not a number`);
      return false;
    }
  }
}
// validateNumeric(23);
// validateNumeric("22");
// validateNumeric(22.3);
// validateNumeric("22.33");
// validateNumeric("22.33.88");
// validateNumeric("sdf222");
// validateNumeric(NaN);
// validateNumeric(null);
// validateNumeric("");
// validateNumeric(undefined);
// validateNumeric();

// date validation
export const validateDate = (e) => {
  // var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/; // DD/MM/YYYY numbers only
  // var pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; // MM/DD/YYYY
  var pattern = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/; // DD/MM/YYY
  return pattern.test(e) === true ? true : false;
};

// identify all labels are verified
export const isAllRowsVerfied = (data) => {
  const isallTrue = data.every((v) => v.isVerified === true);
  return isallTrue;
};

// identify all tables are verified
export const isAllTableVerified = (allTables) => {
  let tables = [...allTables];
  let all = [];
  if (tables !== undefined) {
    tables.forEach((subTable, index) => {
      let temp = [];
      subTable.forEach((sub, i) => {
        temp.push(sub?.reverse()[0]);
      });
      all = [...all, ...temp];
    });
  }
  let allData = all.every((v) => v === true);
  return allData;
};

export const trimLower = (e) => {
  if (e === undefined) return e;
  return e?.trim()?.toLowerCase();
};

// vlidate the special charaters
export const validateNumSpecialChar = (e) => {
  if (e === undefined) return null;

  let specialChars = /[^a-zA-Z ]/g;
  let val = e.match(specialChars);

  if (val === null) {
    return false;
  } else {
    let remain = val.filter((f) => {
      if (f === ",") return false;
      if (f === ".") return false;
      if (f === "-") return false;
      if (f === "'") return false;
      if (f === "_") return false;
      // if (f === '"') ;
      return true;
    });

    return remain.length > 0 ? true : false;
  }
};

/**
 *
 * @param {label is object} e
 * @param {entire data is an Array} data
 * @returns
 */
export const getDefaultValue = (e, data) => {
  let val = "";
  let temp = data?.find((f) => f?.label === e.label);
  if (temp !== undefined) {
    val = temp.value;
  }
  return val;
};

/**
 *
 * @param {row name string} row
 * @param {column name string} column
 * @param {data is an Array} data
 * @returns
 */
export const getDefaultTableValue = (row, column, data) => {
  let val = "";
  let findRow = data?.find((f) => f?.rowName === row);
  if (findRow !== undefined) {
    let findColumn = findRow.data.find((f) => f.label === column);
    if (findColumn !== undefined) {
      val = findColumn.value;
    }
  }
  return val;
};

// export const getTableIndex = (tables, invoiceHeader) => {
//   let ind = null;
//   tables.forEach((e, i) => {
//     e.forEach((tbh, tbhInd) => {
//       if (tbhInd === 0) {
//         tbh.forEach((g, gi) => {
//           if (invoiceHeader.indexOf(g) >= 0 && ind === null) {
//             ind = i;
//             console.log("?????/// ", tbh, "tables index ", i);
//           }
//         });
//       }
//     });
//   });

//   return ind !== null ? ind : 0;
// };

/**
 *
 * @param {Array} arry
 * @returns duplicate elements in array
 */
export const toFindDuplicates = (arry) => {
  return arry.filter((item, index) => arry.indexOf(item) !== index);
};

export const validateEmail = (input) => {
  if (!input) return false;
  var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  //  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    // alert("Valid email address!");
    return true;
  } else {
    // alert("Invalid email address!");
    return false;
  }
};

export const getElementById = (id) => {
  return document.getElementById(id)?.value?.trim();
};

/**
 *
 * @param {String} arg => "h1e2l3l4o5"
 * @returns => 12345
 */
export const removeTextFromString = (arg) => {
  if (arg) {
    var str = arg?.toString();
    return str?.replace(/[^0-9.]+/g, "");
  }
  return arg;
};

/**
 *
 * @param {string} arg => Abcd@123
 * @param {number} min => minimum character
 * @returns
 */
export const validatePassword = (arg, min = 8) => {
  let data = {
    lowercase: false,
    uppercase: false,
    number: false,
    specialChars: false,
    min: false,
  };
  if (arg !== null && arg !== undefined && arg.trim() !== "") {
    let lowercase = /[a-z]/g;
    if (lowercase.test(arg)) data.lowercase = true;

    let uppercase = /[A-Z]/g; // /^[A-Z]/g
    if (uppercase.test(arg)) data.uppercase = true;

    let number = /[0-9]/g; // /^[0-9]*$/;
    if (number.test(arg)) data.number = true;

    let nonSpecialChars = /^[A-Za-z0-9 ]+$/;
    if (!nonSpecialChars.test(arg)) data.specialChars = true;

    if (arg.toString().length >= min) data.min = true;
  }

  return data;
};

const validateChar = (val, { min, max, special = false, lower, upper, num, numOnly }) => {
  if (val === null || val === undefined || val === "") return null;

  let str = val?.toString()?.trim();
  let data = {
    lowercase: "Must need one lowercase character",
    uppercase: "Must need one uppercase character",
    number: "Must need one number",
    specialChars: "Special charater not allowed",
    minimum: `Minimum ${min} characters required`,
    maximum: `Maximum ${max} characters allowed`,
    numbersOnly: "Numbers only allowed.",
  };

  if (str !== null && str !== undefined && str !== "") {
    let lowercase = /[a-z]/g;
    if (lower && lowercase.test(str)) return data.lowercase;

    let uppercase = /[A-Z]/g;
    if (upper && uppercase.test(str)) return data.uppercase;

    let numbersOnly = /^[0-9]+$/;
    if (numOnly && !numbersOnly.test(str)) return data.numbersOnly;

    let number = /[0-9]/g;
    if (num && number.test(str)) return data.number;

    let nonSpecialChars = /^[A-Za-z0-9 ]+$/;
    if (special && !nonSpecialChars.test(str)) return data.specialChars;

    if (min && str.length < min) return data.minimum;
    if (max && str.length > max) return data.maximum;
  }

  return null;
};

export { validateChar };
