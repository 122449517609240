import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SidebarProvider } from "./context/SidebarContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { AuthProvider } from "./context/AuthContext";
import { StripeProvider } from "./context/StripeContext";
import ThemedSuspense from "./components/ThemedSuspense";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/app.css";
import "./assets/css/tailwind.css";
import "../src/transalation/i18n";
import App from "./App";
import ErrorBoundary from "./components/Error/ErrorBoundary";

const queryClient = new QueryClient();


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <SidebarProvider>
        <SnackbarProvider>
          <StripeProvider>
            <AuthProvider>
              <ErrorBoundary>
                <Suspense fallback={<ThemedSuspense />}>
                  <QueryClientProvider client={queryClient}>
                    <GoogleOAuthProvider
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    >
                      <App />
                    </GoogleOAuthProvider>
                  </QueryClientProvider>
                </Suspense>
              </ErrorBoundary>
            </AuthProvider>
          </StripeProvider>
        </SnackbarProvider>
      </SidebarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
