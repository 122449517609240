import React, { lazy, useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
const Layout = lazy(() => import("../../containers/Layout"));

export default function ProtectedRoute() {
  const { user } = useContext(AuthContext);

  return user ? <Layout /> : <Navigate to="/auth/login" />;
}
