import React from "react";
import useSnackAlert from "../hooks/useSnackAlert";
import SnackAlert from "../components/Snackbar/SnackAlert";

// create context
export const SnackbarContext = React.createContext();

export const SnackbarProvider = ({ children }) => {
  const { showMessage, getProps } = useSnackAlert();

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <SnackAlert {...getProps} />
    </SnackbarContext.Provider>
  );
};
