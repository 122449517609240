import React, { lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import PasswordSet from "./pages/PasswordSet";

const EmailVerification = lazy(() => import("./pages/EmailVerification"));

function App() {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;

  useEffect(() => {
    if (resolvedLanguage === "ar") {
      // document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [resolvedLanguage, i18n]);

  return (
    <>
      <AccessibleNavigationAnnouncer />
      <Routes>
        {/* <ForwardRoute name="Route" path="/auth/*" element={<Auth />} /> */}
        <Route path="/auth/*" element={<ForwardRoute />} />
        <Route path="/app/*" element={<ProtectedRoute />} />
        <Route path="/password-set" element={<PasswordSet />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    </>
  );
}

export default App;
