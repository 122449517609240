import React, { memo } from "react";
import PropTypes from "prop-types";

function TextFieldAlert({ message, open, className, marginTop }) {
  if (open === false) return null;
  return (
    <div
      className={`text-xs text-red-500 ${
        marginTop ? marginTop : "mt-1.5"
      } pl-0.5 ${className}`}
    >
      {message}
    </div>
  );
}

TextFieldAlert.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
};
export default memo(TextFieldAlert);
