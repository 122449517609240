import React, { lazy, useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
const Auth = lazy(() => import("../../containers/Auth"));

export default function ForwardRoute() {
  const { user } = useContext(AuthContext);

  return <>{user ? <Navigate to={"/app/home"} /> : <Auth />}</>;
}
